export const modules = {
    toolbar: [
      ["bold", "italic", "underline"],
      [
        // { list: "ordered" },
        { list: "bullet" },
        // { indent: "-1" },
        // { indent: "+1" }
      ],
      // ["link"]
    ]
  };